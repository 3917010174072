import Vue from "vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import LodashPlugin from "@/plugins/lodash";
import CurrencyPlugin from "@/plugins/currency";
import ApiPlugin from "@/plugins/api";
import FontAwesomePlugin from "@/plugins/font-awesome";
import TimeFormattingPlugin from "@/plugins/time-formatting";
import WsVueErrorHandler from "@/error-handler/ws-vue-error-handler.js";
import ReportErrorPlugin from "@/plugins/report-error";

Vue.config.devtools = true;

Vue.use(LodashPlugin);
Vue.use(CurrencyPlugin);
Vue.use(ApiPlugin);
Vue.use(FontAwesomePlugin);
Vue.use(TimeFormattingPlugin);
Vue.use(ReportErrorPlugin);

// eslint-disable-next-line vue/component-definition-name-casing
Vue.component("font-awesome-icon", FontAwesomeIcon);

import "./generic.scss";
import pluralize from "pluralize";

const files = require.context("./components", true, /\.vue$/i);
files
  .keys()
  .map((key) =>
    Vue.component(key.split("/").pop().split(".")[0], files(key).default)
  );

// From https://github.com/vuejs/vue/blob/dev/src/core/util/env.js
// Workaround for https://github.com/vuejs/vue/issues/11165
const inBrowser = typeof window !== "undefined";
const UA = inBrowser && window.navigator.userAgent.toLowerCase();
const isEdge = UA && UA.indexOf("edge/") > 0;
const isChrome = UA && /chrome\/\d+/.test(UA) && !isEdge;

Vue.use({
  install(Vue) {
    Vue.prototype.$missingProductIcon = "bowl-rice";
    Vue.prototype.$pluralize = pluralize;
  },
});

const createVue = () => {
  new Vue({
    el: "#app",
    methods: {
      checkCanRegisterErrorHandler() {
        if (fetch === undefined) {
          console.info(`Unable to capture error, fetch not defined`);
          return;
        }

        if (window.errorsUrl === undefined) {
          console.info(`Unable to capture error, errorsUrl is undefined`);
          return;
        }

        if (window.errorsAppId === undefined) {
          console.info(`Unable to capture error, errorsAppId is undefined`);
          return;
        }

        console.info(`Capturing Vue errors.`);
      },
    },
    created() {
      console.log(`Generic checkout version 1.0.0`);
      this.checkCanRegisterErrorHandler();
    },
    errorCaptured(err) {
      WsVueErrorHandler(err);
    },
  });
};

if (isChrome) {
  window.addEventListener("pageshow", createVue);
} else {
  createVue();
}
