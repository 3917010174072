// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js!@fontsource/merriweather-sans/index.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../node_modules/css-loader/dist/cjs.js!@fontsource/merriweather-sans/300.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../node_modules/css-loader/dist/cjs.js!@fontsource/merriweather-sans/500.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
exports.push([module.id, "body{margin:0;padding:0;font-family:\"Merriweather Sans\";font-weight:300;font-size:14px;--very-transparent-grey: rgba(0, 0, 0, 0.04);--very-light-grey: rgb(244, 244, 244);--light-grey: rgb(233, 233, 233);--grey: rgb(200, 200, 200);--dark-grey: rgb(120, 120, 120);--text-muted: rgb(52, 59, 66)}*{box-sizing:border-box}h1,h2,h3,h4,h5{margin:0;font-weight:500}h1{font-size:1.3rem}h2{font-size:1.15rem}h3{font-size:1rem;font-weight:bold}a{color:inherit}input[type=file]::-webkit-file-upload-button{color:inherit}input:is([type=button],[type=submit],[type=reset]),input[type=file]::file-selector-button,button{color:inherit}", ""]);
// Exports
module.exports = exports;
