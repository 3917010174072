// Vue-specific error handler.
export default function (error) {
  if (
    fetch === undefined ||
    window.errorsUrl === undefined ||
    window.errorsAppId === undefined
  ) {
    // These are logged in app.js in case of a configuration error.
    return;
  }

  // Although window.onerror receives line_number and column_number, Vue doesn't.
  // Instead, we can parse the entire stack server-side and extract these values.

  // Make sure the original onerror is called.
  //console.log(`Sending error`);
  fetch(window.errorsUrl, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    redirect: "follow", // manual, *follow, error
    body: JSON.stringify({
      app_id: window.errorsAppId,
      url: window.location.href,
      message: error.message,
      stack: error.stack,
    }),
  });
}
